<template>
	<!-- 收藏 -->
	<div class="collect banquan-dialog">
		<div class="collect-inner add">
			<div class="title">{{rename ? '重命名文件夹' : '新建文件夹'}}</div>
			<div class="collect-input">
				<input type="text" placeholder="新建文件夹名称" maxlength="10" v-model="folderName">
				<div :class="errorMsg == '' ? '' : 'red'">{{errorMsg}}</div>
			</div>
			<div class="collect-btn">
				<div @click="$emit('close')">取消</div>
				<div class="active" @click="submitBtn">确定</div>
			</div>
			<div class="banquan-close" @click="$emit('close')"><span class="iconfont icon-close"></span></div>
		</div>
	</div>

</template>

<script>
import { GlobalApi } from "@/config/axios/api";

export default {
	name: 'collect',
	/**
	 * rename		重命名
	 * moveFold	移动收藏夹
	 * dirObj   重命名的文件夹信息
	 * category   1 图片  2 视频
	 */
	props: ['rename', 'moveFold', 'dirObj', 'category'],
	data () {
		return {
			errorMsg: '',
			folderName: '',
		}
	},

	computed: {

	},
	created () {
		this.folderName = this.dirObj && this.dirObj.name ? this.dirObj.name : '';
	},
	mounted () {

	},
	destroyed () {

	},
	methods: {

		submitBtn () {
			if (this.folderName.trim() == '') {
				this.errorMsg = '请输入文件夹名称'
				return
			}
			if (this.rename) {
				GlobalApi.editDirectory({ name: this.folderName, id: this.dirObj.id }).then(res => {
					if (res.status == 1) {
						this.$message.success({ message: '文件夹重命名成功' });
						this.$emit('close', true);
					} else {
						this.errorMsg = res.msg ? res.msg : '文件夹重命名失败';
					}
				})
			} else {
				GlobalApi.addDirectory({ name: this.folderName, category: this.category }).then(res => {
					if (res.status == 1) {
						this.$message.success({ message: '新建文件夹成功' });
						this.$emit('close', true);
					} else {
						this.errorMsg = res.msg ? res.msg : '新建文件夹失败';
					}
				})
			}
		}
	},
}
</script>

<style lang="scss" scoped>
.collect-inner {
	width: 360px;
	// height: 420px;
	background: #FFFFFF;
	border-radius: 8px;
	padding: 43px 50px 42px;
	box-sizing: border-box;
	position: relative;

	.title {
		text-align: center;
		margin-bottom: 60px;
		font-size: 22px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #2B2A24;
		line-height: 30px;
	}

	.collect-input {
		margin: 0 0 37px;

		input {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #2B2A24;
			line-height: 20px;
			outline: none;
			width: 100%;
			border: none;
			padding-bottom: 11px;
		}

		div {
			border-top: 1px solid #222222;
			font-size: 12px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			line-height: 17px;
			padding-top: 3px;

			&.red {
				border-top: 1px solid #FF0000;
				color: #FF0000;
			}
		}

	}

	.collect-btn {
		@include flex(center, space-between);

		div {
			width: 120px;
			height: 38px;
			text-align: center;
			border-radius: 4px;
			border: 1px solid #222222;
			font-size: 12px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #1F1F1F;
			line-height: 36px;
			cursor: pointer;

			&.active {
				background: #222222;
				color: #FFFFFF;
			}
		}
	}
}
</style>
