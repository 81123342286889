<template>
  <!-- 个人中心 - 收藏夹 -->
  <div class="collect-index">
    <ul class="type">
      <li :class="{active: category == 1}" @click="imgsFun(1)">图片</li>
      <li :class="{active: category == 2}" @click="imgsFun(2)">视频</li>
    </ul>
    <ul class="folder">
      <li @click="gotoFolder(item)" v-for="item in dataList" :key="item.id"
        :class="item.files_count > 0 ? 'hasbox' : ''">
        <div class="folder-bg">
          <img class="has" v-if="item.files_count > 0 && item.cover != ''" :src="item.cover" alt="" />
          <div class="fenmtk" v-if="item.files_count == 0 || item.cover == ''">
            <img src="@/assets/image/folder.png" alt="" />
          </div>
          <div class="bg"></div>
          <div class="more" @click.stop="showCaozuo(item)"><span class="iconfont icon-More"></span></div>
        </div>
        <p class="text-hidden">{{item.name}}</p>
        <span>{{item.files_count}}个内容</span>
        <div class="caozuo" v-if="item.show">
          <div @click.stop="renameCollect(item)">重命名</div>
          <div @click.stop="delCollect(item.id)">删除</div>
        </div>
      </li>
    </ul>
    <add-collect-dialog v-if="collect.visible" :rename="collect.rename" :dirObj="collect.dirObj" :category="category"
      @close="closeDialog" />
    <default-page v-if="dataList.length == 0" status="nocollect"></default-page>
  </div>
</template>
<script>
import AddCollectDialog from "@/components/personal/addCollectDialog";
import DefaultPage from "@/components/default";
import { GlobalApi } from "@/config/axios/api";
export default {
  name: "DirectoryIndex",
  data () {
    return {
      dataList: [],
      collect: {
        visible: false,
        rename: false,
        dirObj: {}
      },
      category: 1,   //1图片  2视频
    }
  },
  created () {
    this.category = this.$route.query.category || 1;
    this.getList()
  },
  mounted () {
    document.body.addEventListener('click', () => {
      this.dataList.map(item => {
        item.show = false;
        return item;
      })
    })
  },
  components: { AddCollectDialog, DefaultPage },
  methods: {

    gotoFolder (item) {
      this.$router.push('/personal/detail/' + item.id + '?name=' + item.name + '&category=' + this.category);
    },
    showCaozuo (item) {
      this.dataList.map(item => {
        item.show = false;
        return item;
      })
      item.show = true
    },
    getList () {
      GlobalApi.getDirectoryList({ category: this.category }).then(res => {
        if (res.status == 1) {
          res.data.map(item => {
            item.show = false;
            return item;
          })
          this.dataList = res.data;
        }
      })
    },
    // 关闭收藏弹窗
    closeDialog (refresh) {
      if (refresh) {
        this.getList()
      }
      this.collect.visible = false
    },
    // 新建文件夹
    addFolder () {
      this.collect.visible = true;
      this.collect.rename = false;
      this.collect.dirObj = {};
    },
    // 重命名收藏夹
    renameCollect (item) {
      this.collect.visible = true;
      this.collect.rename = true;
      this.collect.dirObj = item;
    },
    // 删除收藏夹
    delCollect (id) {
      this.$confirm('是否确认删除该文件夹', '确认删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'banquan-message banquan-message-2',
        center: true
      }).then(() => {
        GlobalApi.delDirectory({ id: id }).then(res => {
          if (res.status == 1) {
            this.getList()
            this.$message.success({ message: '文件夹删除成功' });
          }
        })
      }).catch(() => { });
    },
    imgsFun (data) {
      this.category = data;
      this.$router.replace({
        path: "/personal",
        query: {
          category: this.category,
        },
      });
      this.getList();
    }
  }
};
</script>
<style scoped lang="scss">
.collect-index {
  padding: 0 66px 0 41px;

  .type {
    @include flex('', '');
    margin-bottom: 34px;

    li {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #1F1F1F;
      text-align: center;
      line-height: 25px;
      margin-right: 50px;
      cursor: pointer;

      &.active {
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: $activeColor;
        position: relative;

        &::after {
          content: "";
          display: block;
          width: 34px;
          height: 3px;
          background: $activeColor;
          border-radius: 2px;
          position: absolute;
          bottom: -6px;
          left: 1px;
        }
      }

    }
  }

  .folder {
    @include flex('', '');
    flex-wrap: wrap;

    li {
      width: 190px;
      position: relative;
      margin-right: 30px;
      margin-bottom: 40px;
      cursor: pointer;

      .folder-bg {
        width: 100%;
        height: 190px;
        background: #F0F4F5;
        border-radius: 8px;
        padding-top: 49px;

        &>img {
          @include wh(167px, 96px);
          display: block;
          margin: 0 auto;
          object-fit: cover;
        }

        .fenmt {
          position: absolute;
          width: 100%;
          height: 100%;
          background: linear-gradient(135deg, #fa6e62 0%, #ea4336 100%);
          border-radius: 8px;
          left: 0;
          top: 0;

          img {
            display: block;
            margin: 25px auto;
            width: 146px;
            height: 146px;
          }
        }

        .fenmtk {
          img {
            display: block;
            height: 86px;
            margin: 0 auto;
          }
        }
      }

      &:hover {
        .more {
          display: block;
        }
      }

      &:last-of-type {
        margin-right: auto;
      }

      .bg {
        display: none;
        z-index: -2;
        width: 174px;
        height: 116px;
        background: #E9EFF1;
        border-radius: 8px;
        position: absolute;
        top: -8px;
        left: 8px;
      }

      .more {
        width: 20px;
        height: 34px;
        background: rgba(216, 216, 216, 0.3);
        border-radius: 4px;
        color: #1f1f1f;
        text-align: center;
        line-height: 34px;
        display: none;
        position: absolute;
        top: 0px;
        right: 14px;
        cursor: pointer;
        transform: rotate(90deg);

        span {
          font-size: 18px;
        }
      }

      p {
        float: left;
        margin: 14px 0 2px 0;
        width: 100%;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1F1F1F;
        line-height: 22px;
      }

      &>span {
        float: left;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 17px;
      }

      .caozuo {
        // display: none;
        z-index: 1;
        position: absolute;
        top: 0;
        right: -94px;
        width: 90px;
        // height: 76px;
        background: #252424;
        border-radius: 8px;
        padding: 7px 0;
        text-align: center;

        div {
          width: 90px;
          line-height: 30px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          cursor: pointer;

          &.active,
          &:hover {
            background: #080808;
          }
        }
      }
    }

    .hasbox {
      .folder-bg {
        padding: 0;

        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
      }

      .caozuo {
        // display: none;
        z-index: 1;
        position: absolute;
        top: 0;
        right: -94px;
        width: 90px;
        // height: 76px;
        background: #252424;
        border-radius: 8px;
        padding: 7px 0;
        text-align: center;

        div {
          width: 90px;
          line-height: 30px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          cursor: pointer;

          &.active,
          &:hover {
            background: #080808;
          }
        }
      }

      &.active {
        .folder-bg {
          background: #FFF1D9;

          img {
            @include wh(116px, 92px);
          }

          .bg {
            background: #FAEACF;
          }
        }
      }
    }
  }

  .unselect {
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
}
</style>
